<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      pills
      align="right"
      content-class="mt-3"
      @activate-tab="onActivateTabs($event)"
    >
      <b-tab
        ref="liveTransactionTab"
        title="Live Transaction"
        lazy
        @click="onTabClick($event, 'liveTransaction')"
      >
        <live-transaction />
      </b-tab>

      <b-tab
        ref="payoutTab"
        active.sync
        title="Payout"
        lazy
        @click="onTabClick($event, 'payout')"
      >
        <payout-info />
      </b-tab>

      <b-tab
        v-if="IS_ADMIN_PORTAL"
        ref="informationTab"
        title="Information"
        lazy
        @click="onTabClick($event, 'information')"
      >
        <business-info-component />
      </b-tab>
      <b-tab
        ref="reportsTab"
        active.sync
        title="Reports"
        lazy
        @click="onTabClick($event, 'reports')"
      >
        <reports-component :merchant-id="merchantId" />
      </b-tab>

      <b-tab
        ref="collectionsTab"
        title="Collections"
        lazy
        @click="onTabClick($event, 'collections')"
      >
        <business-collection-component />
      </b-tab>
      <b-tab
        ref="poolFundTab"
        title="Pool Fund"
        lazy
        @click="onTabClick($event, 'poolFund')"
      >
        <master-virtual-page />
      </b-tab>
      <b-tab
        v-if="IS_ADMIN_PORTAL"
        ref="subscriptionsTab"
        title="Subscriptions"
        lazy
        @click="onTabClick($event, 'subscriptions')"
      >
        <business-subscriptions-component />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import reportsComponent from '@/views/administration/accounts/details/reports/reports.vue'
import businessInfoComponent from '@/views/administration/accounts/details/information/businessInfo.vue'
import businessCollectionComponent from '@/views/administration/accounts/details/collections/companyCollections.vue'
import businessSubscriptionsComponent from '@/views/administration/accounts/details/subscriptions/companySubscriptions.vue'
import MasterVirtualPage from '@/views/administration/accounts/details/poolFund/masterVirtualPage.vue'
import LiveTransaction from '@/views/administration/accounts/details/liveTransaction/liveTransaction.vue'
import PayoutInfo from '@/views/administration/accounts/details/payout/payoutInfo.vue'

export default {
  components: {
    PayoutInfo,
    LiveTransaction,
    MasterVirtualPage,
    businessInfoComponent,
    reportsComponent,
    businessCollectionComponent,
    businessSubscriptionsComponent,
  },
  data() {
    return {
      tabIndex: null,
      accountName: '',
      merchantId: '',
    }
  },
  computed: {},
  async mounted() {
    this.isBusy = false
    await this.$store.dispatch('systemConstants/getSystemConstants')

    const payload = {
      accountId: this.$route.params.id,
    }
    await this.$store.dispatch('accounts/getAccountUsersByAccountId', payload).then(res => {
      if (res.data.response_code === 2000) {
        this.accountName = res.data.data.accounts.account_name
        this.merchantId = res.data.data.accounts.merchant_id
        const breadcrumbUpdatePayload = [
          { title: 'Accounts', route: 'administration-accounts' },
          { title: this.accountName },
          { title: 'Live Transaction' },
        ]
        this.$store.dispatch('accounts/updateCurrentViewAccount', res.data.data.accounts)
        this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
      }
    })
  },
  methods: {
    onTabClick(e, location) {
      console.log(e, location)
    },
    onActivateTabs(e) {
      console.log('activated tab ----------------------------->', e)
    },
  },
}
</script>

<style>

</style>
