<template>
  <div>
    <template v-if="colType === 'monetary'">
      <vue-numeric
        v-if="colData"
        read-only
        :precision="2"
        :currency="myCurrency"
        separator=","
        :value="colData"
      />
      <vue-numeric
        v-else
        read-only
        :precision="2"
        :currency="myCurrency"
        separator=","
        :value="0"
      />
    </template>
    <template v-if="colType === 'percentage'">
      <vue-numeric
        v-if="colData"
        read-only
        :precision="2"
        currency-symbol-position="suffix"
        currency="%"
        separator=","
        :value="colData"
      />
      <vue-numeric
        v-else
        read-only
        :precision="2"
        currency-symbol-position="suffix"
        currency="%"
        separator=","
        :value="0"
      />
    </template>
    <template v-if="colType === 'string'">
      {{ colData }}
    </template>
    <template v-if="colType === 'underscore_string'">
      {{ humanise(colData) }}
    </template>
    <template v-if="colType === 'email'">
      {{ colData }}
    </template>
    <template v-if="colType === 'bankname'">
      {{ getBankName(colData) }}
    </template>
    <template v-if="colType === 'code'">
      <code>{{ colData }}</code>
    </template>
    <template v-if="colType === 'date'">
      {{ transformDate(colData, false, 'FF' ) }}
    </template>
    <template v-if="colType === 'flag'">
      {{ colData }}
    </template>
    <template v-if="colType === 'number'">
      {{ colData }}
    </template>
    <template v-if="colType === 'decimal'">
      {{ colData }}
    </template>
    <template v-if="colType === 'boolFlag'">
      <template v-if="colData">
        <feather-icon
          size="16"
          class="text-success"
          icon="CheckIcon"
        />
      </template>
      <template v-else-if="!colData">
        <feather-icon
          size="16"
          class="text-danger"
          icon="XIcon"
        />
      </template>
      <template v-else>
        <feather-icon
          size="16"
          icon="XIcon"
        />
      </template>
    </template>
    <template v-if="colType === undefined">
      {{ colData }}
    </template>
    <template v-if="colType === 'nestedKey'">
      {{ processNestedData(rowData.item, colName) }}
    </template>
    <template v-if="colType === 'nestedKeyArray'">
      {{ processNestedData(rowData.item, colName, true) }}
    </template>

    <template v-if="colType === 'fe_payment_method'">
      <span class="text-capitalize">{{ stringCleanup(colData) }}</span>
    </template>
    <template v-if="colType === 'fe_payment_mode'">
      <span class="text-capitalize">{{ stringCleanup(colData) }}</span>
    </template>
    <template v-if="colType === 'fe_payment_provider'">
      {{ providerTranslate(rowData) }}
    </template>
    <template v-if="colType === 'api_payment_mode'">
      {{ displaySystemConstantsLabel('payment_mode', colData ) }}
    </template>

    <template v-if="colType === 'prefund_balance'">

      <template v-if="rowData.item.description === 'SUBTRACT_AMOUNT_FROM_TRANSACTION_POOL' ||rowData.item.description === 'SETTLEMENT_TO_BANK' ||rowData.item.description === 'TOTAL_OUTSTANDING_FEE_FOR_SETTLEMENT' ||rowData.item.description === 'SETTLEMENT_TO_PREFUND' ||rowData.item.description === 'SUBTRACTION_TRANSACTION_POOL' ||rowData.item.description === 'SUBTRACT_TRANSACTION_POOL_FOR_PREFUND_FROM_ADMIN_ACCOUNT' || rowData.item.description === 'ADD_AMOUNT_TO_COLLECTION_POOL'">
        -
      </template>

      <template v-else>
        <template v-if="colData">
          <vue-numeric
            read-only
            :precision="2"
            :currency="myCurrency"
            separator=","
            :value="colData"
          />
        </template>
        <template v-if="colData === 0">
          <vue-numeric
            read-only
            :precision="2"
            :currency="myCurrency"
            separator=","
            :value="0"
          />
        </template>
      </template>

    </template>

    <template v-if="colType === 'translate_masterpool_description'">
      <template v-if="colData === 'SUBTRACT_AMOUNT_FROM_PREFUND_POOL'">
        Payout Charges
      </template>
      <template v-if="colData === 'SUBTRACT_AMOUNT_IN_PREFUND_POOL'">
        Deduct Balance From Payout Pool
      </template>
      <template v-if="colData === 'SUBTRACT_AMOUNT_OF_FEE_IN_PREFUND_POOL'">
        Payout
      </template>
      <template v-if="colData === 'SUBTRACTION_TRANSACTION_POOL'">
        Payment Charges
      </template>
      <template v-if="colData === 'ADD_AMOUNT_TO_COLLECTION_POOL'">
        Payment
      </template>
      <template v-if="colData === 'SETTLEMENT_TO_BANK'">
        Settlement To Bank Account
      </template>
      <template v-if="colData === 'SELF_SETTLEMENT_TO_BANK_FROM_PREFUND_POOL'">
        Self Settlement
      </template>
      <template v-if="colData === 'SUBTRACT_TRANSACTION_POOL_FOR_PREFUND_FROM_ADMIN_ACCOUNT'">
        Transfer Charges
      </template>
      <template v-if="colData === 'ADD_AMOUNT_TO_PREFUND_POOL'">
        Add Balance To Payout Pool
      </template>
      <template v-if="colData === 'TOTAL_OUTSTANDING_FEE_FOR_SETTLEMENT'">
        Payment Charges
      </template>
      <template v-if="colData === 'SETTLEMENT_TO_PREFUND'">
        Deduct Balance From Collection Pool
      </template>
      <template v-if="colData === 'SUBTRACT_AMOUNT_FROM_TRANSACTION_POOL'">
        Transfer Charges
      </template>
    </template>
    <template v-if="colType === 'collection_balance'">

      <template v-if="rowData.item.description === 'SUBTRACT_AMOUNT_FROM_TRANSACTION_POOL' ||rowData.item.description === 'ADD_AMOUNT_TO_PREFUND_POOL' ||rowData.item.description === 'SUBTRACT_AMOUNT_FROM_PREFUND_POOL' ||rowData.item.description === 'SUBTRACT_TRANSACTION_POOL_FOR_PREFUND_FROM_ADMIN_ACCOUNT' ||rowData.item.description === 'SUBTRACTION_TRANSACTION_POOL' || rowData.item.description === 'SUBTRACT_AMOUNT_OF_FEE_IN_PREFUND_POOL'">
        -
      </template>

      <template v-else>
        <template v-if="colData">
          <vue-numeric
            read-only
            :precision="2"
            :currency="myCurrency"
            separator=","
            :value="colData"
          />
        </template>
        <template v-if="colData === 0">
          <vue-numeric
            read-only
            :precision="2"
            :currency="myCurrency"
            separator=","
            :value="0"
          />
        </template>
      </template>

    </template>
    <template v-if="colType === 'charges'">

      <template v-if="rowData.item.description === 'SUBTRACT_TRANSACTION_POOL_FOR_PREFUND_FROM_ADMIN_ACCOUNT'">
        -
      </template>

      <template v-else>
        <template v-if="rowData.item.transaction_type === 'SUBTRACTION'">
          <span class="text-danger">(</span>
        </template>
        <template v-if="colData">
          <vue-numeric
            read-only
            :precision="2"
            :read-only-class="rowData.item.transaction_type === 'ADDITION'?'text-success': rowData.item.transaction_type === 'SUBTRACTION'? 'text-danger': ''"
            :currency="myCurrency"
            separator=","
            :value="colData"
          />
        </template>
        <template v-if="colData === 0">
          <vue-numeric
            read-only
            :precision="2"
            :read-only-class="rowData.item.transaction_type === 'ADDITION'?'text-success': rowData.item.transaction_type === 'SUBTRACTION'? 'text-danger': ''"
            :currency="myCurrency"
            separator=","
            :value="0"
          />
        </template>

        <template v-if="rowData.item.transaction_type === 'SUBTRACTION'">
          <span class="text-danger">)</span>
        </template>
      </template>

    </template>

  </div>
</template>

<script>
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {},
  mixins: [humaniseMixin],
  props: {
    rowData: {

    },
    colName: {
      type: String,
    },
    colType: {
      type: String,
    },
  },
  computed: {
    bankList() {
      const bankList = this.$store.getters['systemConstants/getSystemConstantsByKey']('banks')
      if (bankList) {
        return bankList
      }
      return []
    },
    rowDataObj() {
      return this.rowData
    },
    colData() {
      return this.rowDataObj.item[this.colName]
    },
  },
  methods: {
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)
      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === parseInt(id, 10))
        // return x

        if (x.length > 0) {
          return this.humanise(
            x[0].name,
          )
        }
      }
      return 'NA'
    },
    providerTranslate(str) {
      if (typeof str === 'string' && str.length > 0) {
        let newStr = str.replaceAll('SWITCH_WEBPAY', 'FPX')
        newStr = newStr.replaceAll('SWITCH_BNPL', 'BNPL')
        newStr = newStr.replaceAll('SWITCH_CWALLET', 'CWALLET')
        return `${newStr}`
      }

      if (typeof str === 'object' && str.item.customer_bill_payment_record) {
        return this.stringCleanup(str.item.customer_bill_payment_record.payment_method)
      }
      return 'NA'
    },
    processNestedData(rowDataItem, nestedKey, isArray = false) {
      const splitKey = nestedKey.split('.')
      const key = splitKey[0]
      const skey = splitKey[1]

      if (isArray) {
        console.log()
        return rowDataItem[key][0][skey]
      }
      return rowDataItem[key][skey]
    },
    getBankName(id) {
      const list = this.$store.getters['systemConstants/getBankList']
      if (Array.isArray(list) && list.length > 0) {
        const selected = list.filter(el => el.id === id)
        if (Array.isArray(selected) && selected.length > 0) {
          return selected[0].name
        } return id
      } return id
    },
  },
}
</script>

<style>

</style>
