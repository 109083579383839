const Config = {
  datatableFeatures: {
    extraButtons: [
      {
        buttonLabel: 'Generate Report',
        buttonRef: 'downloadPoolReportRange',
        variant: 'secondary',
      },
    ],
    buttons: {
      createButtonLabel: 'Add New',
      createButtonRoute: 'merchant-api-new',
      editButtonRoute: 'merchant-api-form',
      viewButtonRoute: 'merchant-api-form',
      deleteButtonRoute: 'merchant-api-form',
      createButton: false,
      refresh: true,
    },
    datepicker: true,
    perPageDropdown: true,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'invoice_no', text: 'Invoice No' },
    { value: 'invoice_ref', text: 'Invoice Ref' },
  ],
  formSchema() {
    return {
      interface_name: 'string',
      uuid: 'string',
      callback_url: 'string',
      redirect_url: 'string',
      cancel_url: 'string',
      time_out_url: 'string',
      payment_mode: 'string',
      fpx_bank_selection: 'string',
    }
  },
  formData() {
    return {
      title: {
        edit: 'Edit Merchant API',
        view: 'View Merchant API',
        remove: 'Remove Merchant API',
        create: 'Create New Merchant API',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'interface_name',
            fieldName: 'Interface Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'uuid',
            fieldName: 'Uuid',
            type: 'text',
            rules: '',
            show: {
              edit: false, remove: false, view: false, create: false,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'callback_url',
            fieldName: 'Callback Url',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'redirect_url',
            fieldName: 'Redirect Url',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'cancel_url',
            fieldName: 'Cancel Url',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'time_out_url',
            fieldName: 'Time Out Url',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'payment_mode',
            fieldName: 'Payment Mode',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'fpx_bank_selection',
            fieldName: 'FPX Bank Selection',
            type: 'vselect',
            systemConstantsKey: 'fpx_bank_selection',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'id', sortable: false, visible: false, type: 'number',
        },
        {
          key: 'invoice_no', sortable: false, visible: true, type: 'string',
        },
        {
          key: 'invoice_status_new', label: 'Invoice Status', sortable: false, visible: false, type: 'string',
        },
        {
          key: 'description', sortable: false, visible: true, type: 'translate_masterpool_description',
        },
        {
          key: 'invoice_ref', sortable: false, visible: true, type: 'string',
        },
        {
          key: 'transaction_type', sortable: false, visible: false, type: 'string',
        },
        {
          key: 'charges', label: 'Amount', sortable: false, visible: true, type: 'charges',
        },
        {
          key: 'current_value', sortable: false, visible: false, type: 'monetary',
        },
        {
          key: 'collection_balance', label: 'Collection Pool Balance', sortable: false, visible: true, type: 'collection_balance',
        },
        {
          key: 'transaction_balance', label: 'Transaction Pool Balance', sortable: false, visible: true, type: 'monetary',
        },
        {
          key: 'prefund_balance', label: 'Payout Pool Balance', sortable: false, visible: true, type: 'prefund_balance',
        },
        {
          key: 'overall_balance', sortable: false, visible: true, type: 'monetary',
        },
        {
          key: 'created_at', label: 'Created At', sortable: false, visible: true, type: 'date',
        },
        // {
        //   key: 'actions',
        //   label: 'Action',
        //   class: 'text-right',
        //   visible: true,
        // },
      ],
    }
  },
}

export default Config
