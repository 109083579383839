<template>
  <div>

    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          Downloads
        </h4>
      </template>
      <div>
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="d-flex px-2 py-1">
              <b-button
                size="sm"
                class="mr-1"
                @click="refreshTable"
              >
                Refresh
              </b-button>
              <b-form-select
                v-model="payloadConfig.perPage"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="updatePerPage"
              />
            </div>
          </b-col>

          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            lg="4"-->
          <!--          >-->
          <!--            <div class="d-flex px-2 py-1">-->

          <!--              <flat-pickr-->
          <!--                v-model="tempAdvForm.created_at"-->
          <!--                placeholder="Select Date"-->
          <!--                class="form-control form-control-sm"-->
          <!--                :config="{ mode: 'range',-->
          <!--                           maxDate: new Date().fp_incr(1),-->
          <!--                           dateFormat: 'd-m-Y',}"-->
          <!--                @on-change="function(selectedDates , dateStr , instance ) {-->
          <!--                  onDateRangePickerSelected(selectedDates, dateStr, instance, 'created_at')-->
          <!--                }"-->
          <!--              />-->

          <!--            </div>-->
          <!--          </b-col>-->
          <b-col
            offset="4"
            cols="12"
            lg="4"
          >

            <div class="d-flex px-2 py-1">
              <b-form-input
                v-model="payloadConfig.searchTerm"
                size="sm"
                placeholder="Search"
              />

              <b-button
                size="sm"
                class="mr-1"
                @click="updateTable(payloadConfig)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        responsive
        :fields="TableHeader"
        :per-page="payloadConfig.perPage"
        :items="LIstOfDownloadables"
        show-empty
      >
        <template #empty="scope">
          <div class="p-3 text-center">
            No records
          </div>
        </template>
        <template #emptyfiltered="scope">
          <div class="p-3 text-center">
            No records matching filter
          </div>
        </template>

        <template #cell(show_details)="row">
          <b-button
            size="sm"
            variant="link"
            class="p-0"
            @click="row.toggleDetails"
          >

            <template v-if="row.detailsShowing">
              <feather-icon
                size="24"
                icon="ChevronDownIcon"
              />
            </template>
            <template v-else>
              <feather-icon
                size="24"
                icon="ChevronRightIcon"
              />
            </template>
          </b-button>
        </template>

        <template #row-details="row">
          <!--          <pre>{{ row.item }}</pre>-->
        </template>

        <template #cell(report_generation_type_id)="row">
          <template v-if="row.item.report_generation_type_id === 1">
            Test
          </template>
          <template v-if="row.item.report_generation_type_id === 2">
            Bill
          </template>
          <template v-if="row.item.report_generation_type_id === 3">
            Payout
          </template>
          <template v-if="row.item.report_generation_type_id === 4">
            Pool Fund
          </template>
        </template>
        <template #cell(report_generation_status_id)="row">
          <template v-if="row.item.report_generation_status_id === 1">
            <b-badge variant="warning">
              Pending Generation
            </b-badge>
          </template>
          <template v-if="row.item.report_generation_status_id === 2">
            <b-badge variant="success">
              Generated
            </b-badge>
          </template>
          <template v-if="row.item.report_generation_status_id === 3">
            <b-badge variant="danger">
              Failed to generate
            </b-badge>
          </template>
          <template v-if="row.item.report_generation_status_id === 4">
            <b-badge variant="danger">
              Deleted
            </b-badge>
          </template>
          <template v-if="row.item.report_generation_status_id === 5">
            <b-badge variant="danger">
              Expired
            </b-badge>
          </template>
        </template>

        <template #cell(created_at)="row">
          {{ transformDate(row.item.created_at, false, 'FF' ) }}
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            :disabled="row.item.report_generation_status_id !== 2"
            @click="download(row.item.file_name, row.item.id)"
          >
            Download
          </b-button>
          <b-button
            size="sm"
            class="ml-1"
            @click="viewRawJSON(row.item)"
          >
            View Raw
          </b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-between align-items-center border-top p-2">
        <div>
          Total Records: {{ LIstOfDownloadablesMeta.recordsTotal }}
        </div>
        <b-pagination
          v-model="payloadConfig.currentPage"
          first-number
          last-number
          :disabled="LIstOfDownloadablesMeta.recordsTotal < payloadConfig.perPage"
          :total-rows="LIstOfDownloadablesMeta.recordsTotal"
          :per-page="payloadConfig.perPage"
          @change="pagination($event)"
        />
      </div>

    </b-card>

    <b-modal
      ref="rawDataViewerRef"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Raw Data"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <json-viewer
        :value="currentRowData"
        :expand-depth="5"
        copyable
        boxed
        sort
      />
    </b-modal>
  </div>
</template>

<script>

import JsonViewer from 'vue-json-viewer'
import flatPickr from 'vue-flatpickr-component'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {
    flatPickr, JsonViewer,
  },
  mixins: [humaniseMixin],
  props: {
    merchantId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentRowData: null,
      dtForm: {
        created_at: {
          start: '',
          end: '',
        },
      },

      tempAdvForm: {
        created_at: '',
      },

      payloadConfig: {
        perPage: 50,
        length: 50,
        currentPage: 1,
        searchTerm: '',
        start: 0,
      },
    }
  },
  computed: {
    currentBase() {
      let baseUrl = 'https://stag-portal.leanpay.my'
      if (this.IS_STAGING) {
        baseUrl = 'https://stag-portal.leanpay.my'
      }
      if (this.IS_PRODUCTION) {
        baseUrl = 'https://portal.leanx.io'
      }
      if (this.IS_SANDBOX) {
        baseUrl = 'https://portal.leanx.dev'
      }
      return baseUrl
    },
    TableHeader() {
      const x = [
        {
          key: 'show_details',
          label: '',
          sortable: false,
          visible: true,
        },

        {
          key: 'report_name', label: 'Report Name', sortable: false, visible: true,
        },
        {
          key: 'report_generation_type_id', label: 'Type', sortable: false, visible: true,
        },
        {
          key: 'report_generation_status_id', label: 'Status', sortable: false, visible: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: false, visible: true,
        },
        {
          key: 'action',
          label: 'action',
          class: 'text-right',
          sortable: false,
          visible: true,
        },
      ]

      return x.filter(y => y.visible)
    },

    LIstOfDownloadables() {
      const list = this.$store.getters['reportDownloads/getDownloadableList']
      // if (Array.isArray(list)) {
      //   return list
      // } return []
      return list
    },
    LIstOfDownloadablesMeta() {
      const list = this.$store.getters['reportDownloads/getDownloadableListMeta']

      if (list) {
        const picked = (({ a, c }) => ({ a, c }))(list)
        return list
      }
      return {}
    },
    downloadableList() {
      return this.$store.getters['reportDownloads/getDownloadableList']
    },
    currentMerchantId() {
      return this.merchantId
    },
  },
  async mounted() {
    await this.$store.dispatch('systemConstants/getSystemConstants')

    await this.updateTable(this.payloadConfig)
  },
  methods: {

    onDateRangePickerSelected(selectedDates, dateStr, instance, fieldName) {
      if (selectedDates.length === 2) {
        instance.formatDate(selectedDates[0], 'Y-m-d')

        this.dtForm[fieldName].start = instance.formatDate(selectedDates[0], 'Y-m-d')
        this.dtForm[fieldName].end = instance.formatDate(selectedDates[1], 'Y-m-d')

        this.updateTable(this.payloadConfig)
      }
    },

    async refreshTable() {
      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1
      await this.updateTable(this.payloadConfig)
    },
    async updatePerPage(perPageVal) {
      this.payloadConfig.length = perPageVal

      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1

      console.log(this.payloadConfig)
      await this.updateTable(this.payloadConfig)
    },
    async updateTable(config) {
      const filterValue = [
        {
          filter_column: 'merchant_id',
          filter_value: this.currentMerchantId,
        },
        {
          filter_column: 'view_type_id',
          filter_value: '2',
        },
      ]

      if (this.dtForm.created_at.start !== '') {
        const item = {
          filter_column: 'created_at',
          filter_start: this.dtForm.created_at.start,
          filter_end: this.dtForm.created_at.end,
        }

        filterValue.push(item)
      }

      if (this.payloadConfig.searchTerm) {
        const item = {
          filter_column: 'keyword',
          filter_value: this.payloadConfig.searchTerm,
        }

        filterValue.push(item)
      }

      const payload = {
        length: config.length,
        start: config.start,
        perPage: config.perPage,
        filter_array_objects: JSON.stringify(filterValue),
      }

      await this.$store.dispatch('reportDownloads/getDownloadableList', payload)
    },
    async pagination(e) {
      console.log(e)
      this.payloadConfig.start = (e - 1) * this.payloadConfig.perPage
      await this.updateTable(this.payloadConfig)
    },

    viewRawJSON(rowData) {
      this.currentRowData = rowData
      this.$refs.rawDataViewerRef.show()
    },
    download(filename, id) {
      const link = document.createElement('a')
      let baseUrl = 'https://report.leanpay.my'
      if (this.IS_STAGING) {
        baseUrl = 'https://report.leanpay.my'
      }
      if (this.IS_PRODUCTION) {
        baseUrl = 'https://tools.leanx.io'
      }
      if (this.IS_SANDBOX) {
        baseUrl = 'https://tools.leanx.dev'
      }

      link.href = `${baseUrl}/api/v1/env/mobile/superadmin/DownloadReportGeneration?report_generation_id=${id}&token=${localStorage.getItem('accessToken')}`

      link.download = `${filename}.xlsx`
      link.setAttribute('download', `${filename}.xlsx`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      URL.revokeObjectURL(link.href)
    },
  },
}
</script>
