<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          header-tag="h3"
        >
          <!--            {{ pool }}-->
          <template #header>
            Overall Balance
          </template>
          <h2
            class="text-right"
          >
            <vue-numeric
              read-only
              :precision="2"
              :currency="myCurrency"
              separator=","
              :value="merchantPools.overall_balance_pool"
            />
          </h2>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          header-tag="h3"
        >
          <!--            {{ pool }}-->
          <template #header>
            Collection Pool Balance
          </template>
          <h2 class="text-right">
            <vue-numeric
              read-only
              :precision="2"
              :currency="myCurrency"
              separator=","
              :value="merchantPools.collection_pool"
            />
          </h2>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          header-tag="h3"
        >
          <!--            {{ pool }}-->
          <template #header>
            Transaction Pool Balance
          </template>
          <h2 class="text-right">
            <vue-numeric
              read-only
              :precision="2"
              :currency="myCurrency"
              separator=","
              :value="merchantPools.transaction_pool"
            />
          </h2>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          header-tag="h3"
        >
          <!--            {{ pool }}-->
          <template #header>
            Payout Pool Balance
            <b-button
              class="d-none d-xl-block"
              size="sm"
              variant="primary"
              @click.prevent.self="doCopy(merchantPools.merchant_payout_pool_id, 'Pool Id')"
            >
              Copy ID
              <feather-icon
                icon="CopyIcon"
                size="12"
                class="d-inline text-white"
              />
            </b-button>
          </template>
          <h2 class="text-right">
            <vue-numeric
              read-only
              :precision="2"
              :currency="myCurrency"
              separator=","
              :value="merchantPools.prefund_pool"
            />
          </h2>
        </b-card>
      </b-col>
    </b-row>

    <leanpay-datatable-component
      id="masterPoolTable"
      ref="masterPoolTableRef"
      :selectable-table="false"
      :extra-params="extraParams"
      title="Transaction History"
      store-module="masterPool"
      store-data-endpoint="getRecentMasterPoolBalanceAdmin"
      store-getter-endpoint="getMasterPoolBalanceList"
      pagination-state-key="masterPoolBalanceAdmin"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="invoice_no"
      default-sort-field="id"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      :extra-parameter="{}"
      @payload-update="updatePayload"
      @button-extra-clicked="doExtra"
      @row-action-clicked="tableActionClicked"
    />

    <!--    <b-modal-->
    <!--      id="downloadRequestDone"-->
    <!--      ref="downloadRequestDoneRef"-->
    <!--      header-bg-variant="primary"-->
    <!--      header-text-variant="light"-->
    <!--      header-close-variant="dark"-->
    <!--      :title="'File Download Requested'"-->
    <!--      :size="'sm'"-->
    <!--      ok-title="Go to Downloads"-->
    <!--      cancel-title="Close"-->
    <!--      @ok="traverseToDownloads"-->
    <!--      @hidden="closeModal"-->
    <!--    >-->
    <!--      <p>The report have been requested and will be made available to you soon. You can view all requested report in the download section in report page.</p>-->
    <!--      <p>The generated reports will be removed after 48hours. </p>-->
    <!--    </b-modal>-->
  </div>
</template>

<script>
import { BIcon, BI } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'

import LeanpayDatatableComponent from '@/leanpay-components/LeanpayDatatableComponent.vue'
import Config from '@/views/administration/accounts/details/poolFund/masterPoolFormConfig'

export default {
  components: {
    LeanpayDatatableComponent, BIcon,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      lastPayload: null,
      extraParams: {
        id: this.$route.params.id,
      },
    }
  },

  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    merchantPools() {
      const list = this.$store.getters['masterPool/getMasterPoolLatestBalance']
      if (list) {
        return list
      } return {
        overall_balance_pool: 0,
        prefund_pool: 0,
        collection_pool: 0,
        transaction_pool: 0,
        switch_payout_pool_id: null,
        merchant_payout_pool_id: null,
      }
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
    masterPoolList() {
      return this.$store.getters['masterPool/getMasterPoolBalanceList']
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Accounts', route: 'administration-accounts' },
      { title: this.currentViewAccount.account_name },
      { title: 'Pool Fund' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    // const payload = {
    //   limit: 100,
    //   skip: 0,
    //   id: this.$route.params.id,
    //   start_date: '01-02-2000',
    //   end_date: '27-02-2029',
    //   record_status: 1,
    //   invoice_status: 'success',
    //   search: {
    //     search_enable: false,
    //     search_key: 'WEB_PAYMENT',
    //     search_column: 'payment_mode',
    //     search_replace_word_enable: true,
    //     search_word_replace: ' ',
    //     search_word_replace_to: '_',
    //   },
    //   sort: {
    //     parameter_name: 'created_at',
    //     sort_type: 'desc',
    //   },
    // }
    //
    // await this.$store.dispatch('masterPool/getRecentMasterPoolBalanceAdmin', payload)

    const payload2 = {
      id: this.$route.params.id,
    }
    await this.$store.dispatch('masterPool/getMasterPoolLatestBalanceAdmin', payload2)
  },
  methods: {
    doExtra(e) {
      if (e === 'downloadPoolReportRange') {
        const payload = { ...this.lastPayload }
        this.$store.dispatch('reportDownloads/downloadMasterPoolList', payload).then(res => {
          if (res.data.response_code === 2000) {
            this.$refs.downloadRequestDoneRef.show()
          }
        })
      }
    },
    updatePayload(e) {
      this.lastPayload = e
    },

    traverseToDownloads() {
      this.$router.push({ name: 'merchant-reports' })
    },
    closeModal() {
      this.rangeDate = null
      this.$refs.downloadRequestDoneRef.hide()
    },
    tableActionClicked() {},
  },
}
</script>

<style lang="scss" scoped>
.active {
}
</style>
