<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-card>
        <template #header>
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h4 class="mr-1 mb-0">
              {{ formTitle }}
            </h4>
            <b-button
              v-if="accountInfo.merchant_type"
              @click="convertAffiliate(accountInfo.merchant_type, accountInfo.id)"
            >
              Convert As Referral
            </b-button>
          </div>
        </template>
        <template
          v-if="editMode"
          #footer
        >
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
        </template>

        <h5 class="my-1">
          Contact
        </h5>
        <b-form>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Account Name</label>
                    <b-form-input
                      :value="accountInfo.account_name"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Account Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Merchant ID</label>
                    <b-form-input
                      :value="accountInfo.merchant_id"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Merchant ID"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Website URL</label>
                    <b-input-group
                      size="sm"
                    >
                      <b-form-input
                        :value="accountInfo.url"
                        size="sm"
                        :disabled="!editMode"
                        placeholder="Website URL"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          @click="openURL(accountInfo.url)"
                        >
                          <feather-icon
                            icon="ExternalLinkIcon"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Legal Name</label>
                    <b-form-input
                      :value="accountInfo.name"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Legal Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>NRIC</label>
                    <b-form-input
                      :value="businessOwnerInfo.individual_nric"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="NRIC"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Phone</label>
                    <b-form-input
                      :value="userInfo.phone_number"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Phone"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Email</label>
                    <b-form-input
                      :value="userInfo.email"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Email"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <h5 class="mb-1 mt-2">
                Company Information
              </h5>
              <b-row>
                <!--                <b-col>-->
                <!--                  <b-form-group>-->
                <!--                    <label>Registration Time Period</label>-->
                <!--                    <b-form-input-->
                <!--                      :value="accountInfo.current_subscription_time_period"-->
                <!--                      size="sm"-->
                <!--                      :disabled="!editMode"-->
                <!--                      placeholder="Email"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <b-col>
                  <b-form-group>
                    <label>Company Name</label>
                    <b-form-input
                      :value="companyInfo.company_name"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Company Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Registration Number</label>
                    <b-form-input
                      :value="companyInfo.company_number"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Registration Number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Nature of business</label>
                    <b-form-input
                      :value="companyInfo.nature_of_business"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Nature of Business"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Address Line 1</label>
                    <b-form-input
                      :value="businessOwnerInfo.address_line_one"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Address Line 1"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Address Line 2</label>
                    <b-form-input
                      :value="businessOwnerInfo.address_line_two"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Address Line 2"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Postcode</label>
                    <b-form-input
                      :value="businessOwnerInfo.address_postcode"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Postcode"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>City</label>
                    <!--                    <b-form-input-->
                    <!--                      :value="businessOwnerInfo.address_city"-->
                    <!--                      size="sm"-->
                    <!--                      :disabled="!editMode"-->
                    <!--                      placeholder="Email"-->
                    <!--                    />-->
                    <v-select
                      v-model="businessOwnerInfo.address_city"
                      :clearable="false"
                      disabled=""
                      :reduce="address_state => address_state.id"
                      placeholder="Please select"
                      class="select-size-sm"
                      :options="cityOpts"
                      label="name"
                    />
                  </b-form-group>

                </b-col>
                <b-col>
                  <b-form-group @click="doCopy(businessOwnerInfo.address_state, 'Address state')">
                    <label>State</label>
                    <v-select
                      v-model="businessOwnerInfo.address_state"
                      disabled
                      :clearable="false"
                      :reduce="address_state => address_state.id"
                      placeholder="Please select"
                      class="select-size-sm"
                      :options="statesOpts"
                      label="name"
                      @option:selected="resetCity"
                    />
                    <!--                    <b-form-input-->
                    <!--                      :value="businessOwnerInfo.address_state"-->
                    <!--                      size="sm"-->
                    <!--                      :disabled="!editMode"-->
                    <!--                      placeholder="Email"-->
                    <!--                    />-->
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label>Country</label>
                    <b-form-input
                      :value="businessOwnerInfo.address_country"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Country"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Bank Account Statement Header </label>

                    <template v-if="businessOwnerInfo.bank_account_statement_header === null || businessOwnerInfo.bank_account_statement_header === 'NO_DATA'">
                      <div
                        class="p-2 bg-light mb-1"
                      >
                        No Bank Statement Header
                      </div>
                    </template>
                    <template v-else>
                      <div class="p-1 bg-light mb-1">
                        <b-img
                          :src="pdfIconImage"
                          width="50"
                          @click="openModalViewBankStatementHeader"
                        />
                      </div>

                    </template>

                    <!--                    <b-form-file-->
                    <!--                        v-if="formMode === 'edit'"-->
                    <!--                        v-model="fileInputMulti3"-->
                    <!--                        accept=".pdf"-->
                    <!--                        placeholder="Choose a file or drop it here..."-->
                    <!--                        drop-placeholder="Drop file here..."-->
                    <!--                        @input="onFileSelect3('bank_account_statement_header', 'company_detail_business_owner_detail')"-->
                    <!--                    />-->
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label>Bank Name</label>
                        <b-form-input
                          :value="getBankName(businessOwnerInfo.bank_name)"
                          size="sm"
                          :disabled="!editMode"
                          placeholder="Bank Name"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group>
                        <label>Bank Account Number</label>
                        <b-form-input
                          :value="businessOwnerInfo.business_bank_account_number"
                          size="sm"
                          :disabled="!editMode"
                          placeholder="Bank Account Number"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>

            </b-col>
            <b-col cols="3">
              <!--              <b-row>-->
              <!--                <b-col>-->
              <!--                  <b-form-group>-->
              <!--                    <label>Password Hash</label>-->
              <!--                    <b-form-input-->
              <!--                      :value="userInfo.hashed_password"-->
              <!--                      size="sm"-->
              <!--                      :disabled="!editMode"-->
              <!--                      placeholder="Email"-->
              <!--                    />-->
              <!--                  </b-form-group>-->
              <!--                </b-col>-->
              <!--              </b-row>-->
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Logo</label>
                    <div class="overflow-hidden py-1">
                      <b-img
                        width="200px"
                        :src="companyInfo.logo"
                        class="img-fluid"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group>
                    <label>Created</label>
                    <b-form-input
                      :value="transformDate(accountInfo.created_at, false, 'FF' )"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Created"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label>Last Updated</label>
                    <b-form-input
                      :value="transformDate(accountInfo.updated_at, false, 'FF' )"
                      size="sm"
                      :disabled="!editMode"
                      placeholder="Last Updated"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>

    <b-modal
      ref="ViewBankStatementHeaderPDF"
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      size="lg"
      hide-footer
      title="Bank Account Statement Header"
    >
      <vue-pdf-embed
        v-if="pdfSource.hasPdf"
        :page="1"
        :source="pdfSource.src"
      />
    </b-modal>

    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ rawAccountInfo }}</pre>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import Vue from 'vue'
import humaniseMixin from '@/common/humanise.mixin'
import sampleImagesDummy from './dummy.json'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    VuePdfEmbed,
    quillEditor,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      editMode: false,
      fileInputMulti: [],
      fileArray: [],
      fileArrayFileType: [],
      pdfIconImage: require('@/assets/images/icons/filetype-icons/pdf.png'),

      formTitle: 'Account Details',
    }
  },
  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    pdfSource() {
      let pdfBase64
      if (this.businessOwnerInfo) {
        pdfBase64 = this.businessOwnerInfo.bank_account_statement_header
      }

      if (pdfBase64) {
        const obj = {
          hasPdf: true,
          src: `data:application/pdf;base64,${pdfBase64}`,
        }
        return obj
      }
      return {
        hasPdf: false,
        src: '',
      }
    },
    statesOpts() {
      const list = this.$store.getters['systemConstants/getStates']
      if (list) {
        return list
      }
      return []
    },
    cityOpts() {
      const list = this.$store.getters['systemConstants/getCityByStateId'](this.businessOwnerInfo.address_state)
      if (list) {
        return list
      }
      return []
    },
    accountInfo() {
      console.log('acocuntInfo')
      const details = this.$store.getters['accounts/getAccountAdminAccountUserDetail']
      if (details) {
        console.log('details', details)
        return details.accounts
      }
      return []
    },
    userInfo() {
      console.log('acocuntInfo')
      const details = this.$store.getters['accounts/getAccountAdminAccountUserDetail']
      if (details) {
        console.log('details', details)
        return details.users
      }
      return []
    },
    companyInfo() {
      console.log('acocuntInfo')
      const details = this.$store.getters['accounts/getAccountAdminAccountUserDetail']
      if (details) {
        console.log('details', details)
        return details.company_details
      }
      return []
    },
    businessOwnerInfo() {
      console.log('acocuntInfo')
      const details = this.$store.getters['accounts/getAccountAdminAccountUserDetail']
      if (details) {
        console.log('details', details)
        return details.business_owner_details
      }
      return []
    },
    rawAccountInfo() {
      const details = this.$store.getters['accounts/getAccountAdminAccountUserDetail']
      if (details) {
        return details
      }
      return []
    },
  },
  async mounted() {
    console.log('mounted')
    this.isBusy = false

    await this.$store.dispatch('systemConstants/getSystemConstants')

    const breadcrumbUpdatePayload = [
      { title: 'Accounts', route: 'administration-accounts' },
      { title: this.currentViewAccount.account_name },
      { title: 'Business Information' },
    ]

    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    getBankName(id) {
      const list = this.$store.getters['systemConstants/getBankList']
      if (Array.isArray(list) && list.length > 0) {
        const selected = list.filter(el => el.id === id)
        console.log(selected)
        if (Array.isArray(selected) && selected.length > 0) {
          return selected[0].name
        } return id
      } return id
    },
    openModalViewBankStatementHeader() {
      this.$refs.ViewBankStatementHeaderPDF.show()
    },
    onSubmit() {

    },
    convertAffiliate(merchantType, id) {
      if (merchantType !== 3) {
        this.$swal.fire({
          title: 'Conversion Not Allowed',
          text: `Merchant type ${id} are not allowed`,
          icon: 'error',
        })
        return
      }

      const defaultParam = {
        title: 'Confirmation',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        html: 'Are you sure you want to convert this merchant type to referral?',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'leanx-swal',
          // header: 'bg-danger',
          confirmButton: 'btn btn-sm btn-primary mr-1',
          cancelButton: 'btn btn-sm btn-secondary',
          input: 'form-control',
        },
      }
      Vue.swal(defaultParam).then(result => {
        if (result.isConfirmed) {
          const payload = {
            account_id: id,
          }

          this.$store.dispatch('affiliate/createAffiliateExistingMerchant', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$swal.fire({
                title: 'Successfully Converted',
                text: 'Merchant has successfully converted',
                icon: 'success',
              })

              this.$emit('register-success', true)
            } else {
              this.$swal.fire({
                title: `Failed ${res.data.response_code}`,
                text: res.data.breakdown_errors,
                icon: 'error',
              })

              this.$emit('register-success', false)
            }
          })
        }
      })
    },
    resetCity() {
      if (this.businessOwnerInfo.address_state === '4165116689'
          || this.businessOwnerInfo.address_state === '4416752667'
          || this.businessOwnerInfo.address_state === '4534491713'
      ) {
        this.cityRules = ''
      } else { this.cityRules = 'required' }
      this.businessOwnerInfo.address_city = null
      // alert('hi')
    },
    openURL(url) {
      const isValidUrl = string => {
        let url
        try {
          url = new URL(string)
        } catch (e) {
          return false
        }
        return url.protocol === 'http:' || url.protocol === 'https:'
      }
      if (isValidUrl(url)) {
        console.log('valid url', url)

        window.open(url, '_blank')
      } else {
        window.open(`//${url}`, '_blank')
      }
    },
    getDummy() {
      const dummy = sampleImagesDummy
      this.fileArray = dummy.sampleFiles
      this.fileArray.forEach(item => {
        this.fileArrayFileType.push(this.getBase64FileType(item))
      })
    },
    getBase64(file) {
      return new Promise(((resolve, reject) => {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          console.log('start')
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          console.log('error')
          reject(error)
        }
        reader.onloadend = function () {
          console.log('end', imgResult)
          resolve(imgResult)
        }
      }))
    },
    onFileSelect() {
      this.fileInputMulti.forEach(item => {
        console.log('fileSelected', item)
        this.getBase64(item).then(res => {
          this.fileArray.push(res)
          this.fileArrayFileType.push(this.getBase64FileType(res))
        })
      })
    },
    getBase64FileType(base64string, mimeType = false) {
      const mime = base64string.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
      const extension = base64string.match(/[^:/]\w+(?=;|,)/)[0]
      console.log(base64string)
      if (mimeType) {
        return mime
      }
      return extension
    },
    cancelForm() {
      this.editMode = false
    },
    makeId(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random()
            * charactersLength))
      }
      return result
    },
    viewBase64asImage(base64string) {
      console.log(base64string)
    },
    downloadBase64asImage(base64string, filename, extension) {
      const a = document.createElement('a') // Create <a>
      a.href = `${base64string}` // Image Base64 Goes here
      a.download = `${filename}.${extension}` // File name Here
      a.click() // Downloaded file
    },
    removeUploaded(index) {
      const { fileArray } = this
      const { fileArrayFileType } = this
      const { fileInputMulti } = this
      fileArray.splice(index, 1)
      fileArrayFileType.splice(index, 1)
      fileInputMulti.splice(index, 1)
    },
  },
}
</script>
<style lang="scss">
[dir] textarea.form-control-plaintext {
  padding: 0.7rem 0rem !important;
  height: 2.142rem;
  resize: none;
  overflow: hidden;
}
</style>

<!--<style scoped lang="scss">-->
<!--.action-hover-container {-->
<!--  z-index: 1;-->
<!--  &::after {-->

<!--    z-index: 600;-->
<!--    content: '';-->
<!--    position: absolute;-->
<!--    right: 0;-->
<!--    left: 0;-->
<!--    top: 0;-->
<!--    bottom: 0;-->
<!--  }-->
<!--  &:hover{-->
<!--    z-index: 1000;-->
<!--    background-color: red;-->
<!--  }-->
<!--}-->
<!--</style>-->
