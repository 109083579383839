<template>
  <div>
    <lp-datatable
      query-param="id"
      title="Collections"
      store-module="collections"
      store-data-endpoint="getAdminCollectionByCompId"
      store-getter-endpoint="companyCollectionsList"
      pagination-state-key="companyCollectionsList"
      :features="loadedConfig.datatableFeatures"
      :load-system-constant="true"
      default-search-field="title"
      default-sort-field="created_at"
      :search-options-dropdown="loadedConfig.searchOptsDropdown"
      :conf="loadedConfig"
      @actionBtn-clicked="actionBtnClicked"
      @aditBtn-clicked="editBtnClicked"
      @viewBtn-clicked="viewBtnClicked"
      @deleteBtn-clicked="deleteBtnClicked"
      @extraBtn-clicked="extraBtnClicked"
    />

  </div>
</template>

<script>
import Config from './formConfig'
import humaniseMixin from '@/common/humanise.mixin'

import LpDatatable from '@/leanpay-components/LPDataTable.vue'

export default {
  components: { LpDatatable },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: '  Collection',
      dataGridEndpoint: [],
      module: 'collections',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',

      selectedRecordStatus: 2,
      selectedInvoiceStatus: 'pending',

    }
  },
  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    loadedConfig() {
      if (Config) {
        return Config
      }
      return {}
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Accounts', route: 'administration-accounts' },
      { title: this.currentViewAccount.account_name },
      { title: 'Collections' },
    ]

    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },
  methods: {
    actionBtnClicked(e) {
      console.log(e)
    },
    deleteBtnClicked(e) {
      console.log(e)
    },
    viewBtnClicked(e) {
      console.log(e)
      this.$router.push({
        name: 'administration-accounts-details-collection-info',
        params: {
          collectionId: e,
          mode: 'view',
        },
      })
    },
    editBtnClicked(e) {
      console.log(e)
    },
    extraBtnClicked(e) {
      console.log(e)
    },
  },
}
</script>
