<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mr-auto">
          Subscription Details
        </h4>
        <b-button
          variant="primary"
          size="sm"
          @click="openAdminUpgradePlanModal"
        >
          Upgrade Plan
        </b-button>
      </template>

      <b-row>
        <b-col>
          <h4 class="mb-2 text-success">
            {{ subscriptionInfo.plan_name }}
          </h4>
          <vue-numeric
            read-only-class="h2"
            read-only
            :precision="2"
            :currency="myCurrency"
            separator=","
            :value="subscriptionInfo.plan_charges"
          />
          <p>{{ subscriptionInfo.description }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <h4 class="mb-0 font-weight-bolder">
            Charges
          </h4>
          <p>Your charges based on activated payment channel:</p>
          <section>
            <div
              v-if="subscriptionInfo.fpx_enable"
              class="row"
            >
              <div class="col">
                FPX (B2C)
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  :precision="2"
                  :currency="myCurrency"
                  separator=","
                  :value="subscriptionInfo.fpx_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.fpx_enable"
              class="row"
            >
              <div class="col">
                FPX (B2B)
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  :precision="2"
                  :currency="myCurrency"
                  separator=","
                  :value="subscriptionInfo.fpx_b2b_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.bnpl_enable"
              class="row"
            >
              <div class="col">
                BNPL
              </div>

              <div class="col">
                <vue-numeric
                  read-only
                  currency="%"
                  currency-symbol-position="suffix"
                  :precision="2"
                  separator=","
                  :value="subscriptionInfo.bnpl_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.credit_card_enable"
              class="row"
            >
              <div class="col">
                Credit Card
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  currency="%"
                  currency-symbol-position="suffix"
                  :precision="2"
                  separator=","
                  :value="subscriptionInfo.credit_card_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.ewallet_enable"
              class="row"
            >
              <div class="col">
                E-Wallet
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  currency="%"
                  currency-symbol-position="suffix"
                  :precision="2"
                  separator=","
                  :value="subscriptionInfo.ewallet_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.crypto_enable"
              class="row"
            >
              <div class="col">
                Crypto
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  currency="%"
                  currency-symbol-position="suffix"
                  :precision="2"
                  separator=","
                  :value="subscriptionInfo.crypto_charges"
                />
              </div>
            </div>
            <div
              v-if="subscriptionInfo.paypal_enable"
              class="row"
            >
              <div class="col">
                Paypal
              </div>
              <div class="col">
                <vue-numeric
                  read-only
                  currency="%"
                  currency-symbol-position="suffix"
                  :precision="2"
                  separator=","
                  :value="subscriptionInfo.paypal_charges"
                />
              </div>
            </div>
          </section>

        </b-col>
        <b-col>

          <section>
            <h4 class="mb-0">
              Quota/Usage
            </h4>
            <p>Your plan entitles:</p>
          </section>

          <section class="position-relative">
            <!--              <div-->
            <!--                class="position-absolute"-->
            <!--                style="right: 0; left: 0; top: 0; bottom: 0; background: #fff; opacity: 0.7; z-index: 300"-->
            <!--              >-->
            <!--                <div-->
            <!--                  class="text-center position-absolute w-100"-->
            <!--                  style="top: 30%"-->
            <!--                >-->
            <!--                  <h3>Future Implementation</h3>-->
            <!--                </div>-->
            <!--              </div>-->
            <div class="row">
              <div class="col">
                <section>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Product
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.product"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Payment Form
                    </div>

                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.payment_form"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Bill Form
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.bill_form"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Short URL
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.short_url"
                      />
                    </div>
                  </div>

                </section>
              </div>
              <div class="col">
                <section>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Domain
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.domain"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Catalog
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.catalog"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      Store
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.store"
                      />
                    </div>
                  </div>
                  <div
                    class="row"
                  >
                    <div class="col">
                      API Interface
                    </div>
                    <div class="col">
                      <vue-numeric
                        read-only
                        :precision="0"
                        separator=","
                        :value="subscriptionInfo.api"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      ref="adminUpgradePlanModal"
      hide-footer
      no-close-on-backdrop
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      title="Upgrade Plan"
      @show="fetchSubscriptionPlan"
    >
      <b-form-group
        :class="[{ 'has-error': v$.upgradeSubscriptionForm.referral_key.$error }]"
      >
        <label>Referral Key</label>
        <b-form-input v-model="upgradeSubscriptionForm.referral_key" />
        <template v-for="(error, idx) in v$.upgradeSubscriptionForm.referral_key.$errors">
          <p
            :key="'error-'+idx"
            class="small text-danger"
          >
            {{ error.$message }}
          </p>
        </template>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          v-model="upgradeSubscriptionForm.time_period"
          disabled
          checked="false"
          switch
          inline
        >
          Bill Yearly
        </b-form-checkbox>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
          @click="changePlan"
        >
          Submit
        </b-button>
        <b-button
          class="ml-1"
          type="reset"
          variant="secondary"
          @click="cancelForm"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>

    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">ID: {{ $route.params.id }} Business Subscriptions Components</pre>
    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ accountInfo }}</pre>
    <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ subscriptionInfo }}</pre>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import {
  required,
} from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      upgradeSubscriptionForm: {
        referral_key: { required },
        time_period: { required },
      },
    }
  },
  components: { vSelect },
  data() {
    return {
      fieldSize: 'sm',
      upgradeSubscriptionForm: {
        referral_key: null,
        time_period: null,
      },
    }
  },
  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    periodId() {
      if (this.upgradeSubscriptionForm.time_period) {
        return 2
      } return 1
    },
    timePeriod() {
      return [{ id: 1, name: 'Monthly' }, { id: 1, name: 'Yearly' }]
    },
    templateListNames() {
      const list = this.$store.getters['subscriptionsPlan/getSubscriptionPlanList']
      const array = []
      list.forEach(item => array.push({ id: item.id, name: item.plan_name }))
      return array
    },
    accountInfo() {
      const info = this.$store.getters['subscriptionsPlan/getSelectedSubscription']
      if (info) {
        return info.Account
      }
      return []
    },
    subscriptionInfo() {
      const info = this.$store.getters['subscriptionsPlan/getSelectedSubscription']

      if (info) {
        return info.Subscription
      }
      return []
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Accounts', route: 'administration-accounts' },
      { title: this.currentViewAccount.account_name },
      { title: 'Subscriptions' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    const companyId = this.$route.params.id
    await this.$store.dispatch('subscriptionsPlan/getSubscriptionById', companyId)
  },
  methods: {
    fetchSubscriptionPlan() {
      const payload = {
        limit: 10,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
      }
      this.$store.dispatch('subscriptionsPlan/getSubscriptions', payload)
    },
    openAdminUpgradePlanModal() {
      this.$refs.adminUpgradePlanModal.show()
    },
    changePlan(planId) {
      const valid = this.v$.upgradeSubscriptionForm.$validate()

      if (!valid) {
        return
      }

      const companyId = this.$route.params.id
      const payload = {
        account_id: parseInt(companyId, 10),
        time_period: this.periodId,
        referral_key: this.upgradeSubscriptionForm.referral_key,
      }

      this.$swal.fire({
        customClass: {
          confirmButton: 'btn btn-sm btn-primary',
          cancelButton: ' btn btn-sm btn-secondary ml-1 ',
        },
        html: 'Are you sure you want to upgrade?<br> <br> ',
        title: 'Confirmation',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        denyButtonText: 'Cancel',
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch('subscriptionsPlan/adminUpgradeSubscriptionByReferralKey', payload).then(res => {
            if (res.data.response_code === 2000) {
              this.$store.dispatch('subscriptionsPlan/getSubscriptionById', companyId).then(data => {
                if (data.data.response_code === 2000) {
                  this.$refs.adminUpgradePlanModal.hide()
                }
              })
            }
          })
        }
      })
    },
    cancelForm() {
      this.$refs.adminUpgradePlanModal.hide()
    },
    resetForm() {
      requestAnimationFrame(() => {
        console.log(this.$refs.observer)
        this.$refs.observer.reset()
      })
    },
  },
}
</script>

<style>

</style>
