<script>
import { BCard, BIcon, BIconArrowClockwise } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},
  data() {
    return {

    }
  },
  computed: {
    payoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminPayoutDashboardReporting']

      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        settlement: 0,
        prefund: 0,
        total_all_amount: 0,
        text: '',
      }
    },
  },
  async mounted() {
    const payload = {
      account_id: this.$route.params.id,
    }
    await this.$store.dispatch('dashboard/getCrmReportingPayoutDashboard', payload).then(res => {
      console.log('res.data', res.data.data)
    })
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    copyResponse() {
      if (this.payoutDashboardData.settlement === 0) {
        const text = `Your current payout balance is ${this.myCurrency} ${this.numberWithCommas(this.payoutDashboardData.prefund)}.`
        this.doCopy(text, 'Response')
      } else {
        const text = `Your current payout balance is ${this.myCurrency} ${this.numberWithCommas(this.payoutDashboardData.prefund)}. It will be further increased to ${this.myCurrency} ${this.numberWithCommas(this.payoutDashboardData.total_all_amount)} by 3pm`
        this.doCopy(text, 'Response')
      }
    },
    doCopy(elem, elementName = null) {
      this.$copyText(elem).then(() => {
        let label
        if (elementName) {
          label = elementName
        } else {
          label = 'Text'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${label} copied`,
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>

<template>
  <div>
    <b-row>

      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Payout Balance</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.prefund"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Pending Settlement</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.settlement"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="4"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <div class="d-flex justify-content-between align-items-center">

            <h6>Response</h6>
            <b-button
              size="sm"
              @click="copyResponse"
            >
              Copy
            </b-button>
          </div>
          <template v-if="payoutDashboardData.settlement === 0">
            <p>Your current payout balance is
              <vue-numeric
                read-only
                :precision="2"
                :currency="globalCurrency"
                separator=","
                :value="payoutDashboardData.prefund"
              />
            </p>
          </template>
          <template v-else>
            Your current payout balance is
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.prefund"
            />
            . It will be further increased to
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.total_all_amount"
            />
            by 3pm
            <p>{{ payoutDashboardData.text }}</p>
          </template>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>
