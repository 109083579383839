<template>
  <div>

    <!--    <div class="d-flex justify-content-end mb-1">-->
    <!--      <b-button-->
    <!--        size="sm"-->
    <!--        :variant="reloadEnable? 'warning' : 'dark'"-->
    <!--        @click="toggleReloader"-->
    <!--      >-->
    <!--        <template v-if="reloadEnable">-->
    <!--          Turn Off Reload-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--          Turn On Reload-->
    <!--        </template>-->
    <!--      </b-button>-->
    <!--    </div>-->

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >

        <mini-cards
          :account-id="idNum"
          chart-type="today"
          :labels="todayData.chartData[0].label"
          :card-title="todayData.label"
          :card-value="todayData.amount"
          :series="todayData.chartData"
          :chart-js-obj="todayData.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <mini-cards
          :account-id="idNum"
          chart-type="yesterday"
          :labels="yesterdayData.chartData[0].label"
          :card-title="yesterdayData.label"
          :card-value="yesterdayData.amount"
          :series="yesterdayData.chartData"
          :chart-js-obj="yesterdayData.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <mini-cards
          :account-id="idNum"
          chart-type="lastsevendays"
          :labels="sevenDaysData.chartData[0].label"
          :card-title="sevenDaysData.label"
          :card-value="sevenDaysData.amount"
          :series="sevenDaysData.chartData"
          :chart-js-obj="sevenDaysData.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <mini-cards
          :account-id="idNum"
          chart-type="month"
          :labels="thisMonthData.chartData[0].label"
          :card-title="thisMonthData.label"
          :card-value="thisMonthData.amount"
          :series="thisMonthData.chartData"
          :chart-js-obj="thisMonthData.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <mini-cards
          :account-id="idNum"
          chart-type="lastmonth"
          :labels="lastMonthData.chartData[0].label"
          :card-title="lastMonthData.label"
          :card-value="lastMonthData.amount"
          :series="lastMonthData.chartData"
          :chart-js-obj="lastMonthData.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <mini-cards
          :account-id="idNum"
          chart-type="year"
          :labels="thisYear.chartData[0].label"
          :card-title="thisYear.label"
          :card-value="thisYear.amount"
          :series="thisYear.chartData"
          :chart-js-obj="thisYear.chartjsData"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <div>
          <mini-cards
            :account-id="idNum"
            chart-type="settlement"
            :labels="settlement.chartData[0].label"
            card-title="Settlement"
            :card-value="settlement.amount"
            :series="settlement.chartData"
            :chart-js-obj="settlement.chartjsData"
          />
          <!--          <b-card body-class="border border-primary pb-50">-->
          <!--            <h6>Settlement</h6>-->
          <!--            <h2 class="font-weight-bolder mb-1">-->
          <!--              <vue-numeric-->
          <!--                read-only-->
          <!--                :precision="2"-->
          <!--                :currency="globalCurrency"-->
          <!--                separator=","-->
          <!--                :value="'0'"-->
          <!--              />-->
          <!--            </h2>-->
          <!--            <div style="min-height: 45px" />-->
          <!--          </b-card>-->
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Payout Pool Balance</h6>
          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="prefundBalance"
            />
          </h2>
        </b-card>
      </b-col>
      <!--      <b-col cols="12" lg="3">-->
      <!--        <b-card-->
      <!--          body-class="border border-primary pb-50"-->
      <!--        >-->
      <!--          <h6>Transaction Pool Balance</h6>-->

      <!--          <h2 class="font-weight-bolder mb-1">-->
      <!--            <vue-numeric-->
      <!--              read-only-->
      <!--              :precision="2"-->
      <!--              :currency="globalCurrency"-->
      <!--              separator=","-->
      <!--              :value="transactionPoolBalance"-->
      <!--            />-->
      <!--          </h2>-->
      <!--        </b-card>-->
      <!--      </b-col>-->
      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Collection  (Last 2 days)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="allTimeCollectionAmount"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Payout (Today)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.today_payout"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Payout (Yesterday)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.yesterday_payout"
            />
          </h2>
        </b-card>
      </b-col>
    </b-row>

    <downloadables-view
      v-if="IS_ADMIN_PORTAL"
      :merchant-id="merchantId"
    />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'
import store from '@/store'
import miniCards from '@/views/administration/dashboardComponents/MiniCards.vue'
import DownloadablesView from '@/views/administration/accounts/details/reports/downloadablesView.vue'

export default {
  components: {
    DownloadablesView,
    BCard,
    miniCards,
  },
  mixins: [humaniseMixin],
  props: {
    merchantId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      reloadEnable: false,
      cardTitle: 'Dashboard',
      dataGridEndpoint: [],
      module: 'dashboard',
    }
  },
  computed: {
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    idNum() {
      const paramId = this.$route.params.id
      if (typeof paramId === 'string') {
        return parseInt(paramId, 10)
      }
      return paramId
    },
    DashboardData() {
      const list = this.$store.getters['dashboard/dashboardData']
      if (list !== null) {
        return list
      }
      return []
    },
    poolData() {
      const currentState = this.$store.getters['collectionPool/getMerchantPoolAccountBalance']
      if (currentState) {
        const list = currentState
        if (Array.isArray(list) && list.length) {
          return list
        }
        return []
      }
      return []
    },
    prefundBalance() {
      const pd = this.poolData
      if (Array.isArray(pd) && pd.length) {
        const pfb = pd.filter(item => item.pool_name === 'PREFUND')
        return pfb[0].value
      }
      return 0
    },
    transactionPoolBalance() {
      const pd = this.poolData
      if (Array.isArray(pd) && pd.length) {
        const tfb = pd.filter(item => item.pool_name === 'TRANSACTION')
        return tfb[0].value
      }
      return 0
    },
    allTimeCollectionAmount() {
      const pd = this.poolData
      if (Array.isArray(pd) && pd.length) {
        const atc = pd.filter(item => item.pool_name === 'COLLECTION')
        return atc[0].value
      }
      return 0
    },
    payoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCustomerDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        monthly_payout: 0, yesterday_payout: 0, last_week_payout: 0, today_payout: 0, last_month_payout: 0, year_payout: 0, last_year_payout: 0, total_payout: 0,
      }
    },
    todayData() {
      const list = this.DashboardData
      if (list.days) {
        return {
          label: 'Today',
          amount: list.days.today_sale,
          chartData: [
            {
              label: this.processLabel(list.days.today_range),
              name: 'Today',
              data: list.days.mini_today_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.days.today_range),
            datasets: [
              {
                data: list.days.mini_today_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
    othersData() {
      const list = this.DashboardData
      if (list.others) {
        return {
          label: 'Total Sales Received',
          amount: list.others.total_sales_received,
        }
      }
      return []
    },
    yesterdayData() {
      const list = this.DashboardData
      if (list.days) {
        return {
          label: 'Yesterday',
          amount: list.days.yesterday_sale,
          chartData: [
            {
              label: this.processLabel(list.days.today_range),
              name: 'Yesterday',
              data: list.days.mini_yesterday_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.days.today_range),
            datasets: [
              {
                data: list.days.mini_yesterday_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
    sevenDaysData() {
      const list = this.DashboardData
      if (list.others) {
        return {
          label: 'Last 7 Days',
          amount: list.others.total_last_week_sales,
          chartData: [
            {
              name: 'Last 7 Days',
              data: list.others.mini_last_week_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.others.last_week_range),
            datasets: [
              {
                data: list.others.mini_last_week_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],
          },
        }
      }
      return []
    },
    settlement() {
      const list = this.DashboardData
      if (list.months) {
        return {
          label: 'This Month',
          amount: list.months.this_month_settlement,
          // amount: list.years.yearly_sale,
          chartData: [
            {
              label: this.processLabel(list.months.settlement_range_month),
              name: 'Settlement',
              data: list.months.mini_total_settlement,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.months.settlement_range_month),
            datasets: [
              {
                data: list.months.mini_total_settlement,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
    thisYear() {
      const list = this.DashboardData
      if (list.years) {
        return {
          label: 'This Year',
          amount: list.years.yearly_sale,
          chartData: [
            {
              label: this.processLabel(list.years.yearly_range),
              name: 'This Year',
              data: list.years.mini_yearly_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.years.yearly_range),
            datasets: [
              {
                data: list.years.mini_yearly_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
    thisMonthData() {
      const list = this.DashboardData
      if (list.months) {
        return {
          label: 'This Month',
          amount: list.months.this_month_sale,
          chartData: [
            {
              label: this.processLabel(list.months.monthly_range),
              name: 'This Month',
              data: list.months.mini_monthly_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.months.monthly_range),
            datasets: [
              {
                data: list.months.mini_monthly_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
    lastMonthData() {
      const list = this.DashboardData
      if (list.months) {
        return {
          label: 'Last Month',
          amount: list.months.last_month_sale,
          chartData: [
            {
              label: this.processLabel(list.months.monthly_range),
              name: 'Last Month',
              data: list.months.mini_last_monthly_range,
            },
          ],
          chartjsData: {
            labels: this.processLabel(list.months.monthly_range),
            datasets: [
              {
                data: list.months.mini_last_monthly_range,
                backgroundColor: '#28dac6',
                borderColor: 'transparent',
              },
            ],

          },
        }
      }
      return []
    },
  },
  async mounted() {
    await store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)

    const payload = {
      id: this.$route.params.id,
      skip: 0,
      limit: 50,
    }

    const payload2 = {
      merchantId: this.$route.params.id,
      start_date: '01-02-2000',
      end_date: '27-02-2029',
      record_status: 1,
      invoice_status: 'success',
      search: {
        search_enable: false,
        search_key: 'WEB_PAYMENT',
        search_column: 'payment_mode',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: {
        parameter_name: 'created_at',
        sort_type: 'desc',
      },
    }

    await this.$store.dispatch('collectionPool/getMerchantPoolAccountBalance', payload2).then(res => {
      console.log(res.data)
    })

    const payload3 = {
      id: this.$route.params.id,
    }

    await this.$store.dispatch('dashboard/getAdminCustomerDashboard', payload3).then(res => {
      console.log('res.data', res.data.data)
    })

    await this.$store.dispatch(`${this.module}/getMerchantDashboard`, payload).then(res => {
      const breadcrumbUpdatePayload = [
        { title: 'Accounts', route: 'administration-accounts' },
        { title: this.currentViewAccount.account_name },
        { title: 'Reports' },
      ]

      this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    })
  },
  methods: {

    toggleReloader() {
      this.reloadEnable = !this.reloadEnable

      const _this = this
      setInterval(() => {
        _this.reloader()
      }, 30000)
    },
    async reloader() {
      const payload = {
        id: this.$route.params.id,
        skip: 0,
        limit: 50,
      }

      const payload2 = {
        merchantId: this.$route.params.id,
        start_date: '01-02-2000',
        end_date: '27-02-2029',
        record_status: 1,
        invoice_status: 'success',
        search: {
          search_enable: false,
          search_key: 'WEB_PAYMENT',
          search_column: 'payment_mode',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: 'desc',
        },
      }

      if (this.reloadEnable) {
        this.$store.dispatch('collectionPool/getMerchantPoolAccountBalance', payload2).then(res => {
          console.log(res.data)
        })

        await this.$store.dispatch(`${this.module}/getMerchantDashboard`, payload)
      }
    },
    processLabel(obj) {
      const x = []
      obj.forEach(item => {
        x.push(item.x_axis)
      })
      console.log(x)
      return x
    },
  },
}
</script>

<style>

</style>
