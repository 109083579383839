<template>
  <div>
    <b-card
      body-class="border border-primary pb-50"
      @click="cardClicked(chartType)"
    >
      <h6>{{ humanise(cardTitle) }}
        <template v-if="chartType === 'today'">
          {{ luxonTodayDate.toFormat('dd MMM yyyy') }}
        </template>
        <template v-if="chartType === 'yesterday'">
          {{ luxonTodayDate.plus({days: -1}).toFormat('dd MMM yyyy') }}
        </template>
        <template v-if="chartType === 'month'">
          {{ luxonTodayDate.toFormat('MMM yyyy') }}
        </template>
        <template v-if="chartType === 'lastmonth'">
          {{ luxonTodayDate.plus({months: -1}).toFormat('MMM yyyy') }}
        </template>
        <template v-if="chartType === 'year'">
          {{ luxonTodayDate.toFormat('yyyy') }}
        </template>
      </h6>
      <h2 class="font-weight-bolder mb-1">
        <vue-numeric
          read-only
          :precision="2"
          :currency="globalCurrency"
          separator=","
          :value="cardValue"
        />
      </h2>
      <!--      {{ labels }}-->
      <!-- chart -->
      <!--      {{ chartJsObj }}-->
      <!--      <pre> {{ chartjsData.latestBarChart.data }}</pre><br><br>-->
      <!--      {{ chartjsData.latestBarChart.options }}-->
      <chartjs-component-bar-chart
        :height="65"
        :chart-data="chartJsObj"
        :options="chartjsData.simpleBarChart.options"
      />
      <!--      <chartjs-component-bar-chart-->
      <!--        :height="100"-->
      <!--        :data="chartJsObj"-->
      <!--        :options="chartjsData.latestBarChart.options"-->
      <!--      />-->
      <!--      <vue-apex-charts-->
      <!--        height="50"-->
      <!--        :options="statisticsOrder.chartOptions"-->
      <!--        :series="series"-->
      <!--      />-->

      <!--    {{ series }}-->
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { DateTime } from 'luxon'
import humaniseMixin from '@/common/humanise.mixin'
import chartjsData from './chartjsData'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
    ChartjsComponentBarChart,
  },
  mixins: [humaniseMixin],
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    chartType: {
      type: String,
    },
    noChart: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
    },
    chartJsObj: {
      type: Object,
    },
    labels: {
      type: Array,
    },
    cardValue: {
      type: Number,
      default: 0,
    },
    cardTitle: {
      type: String,
      default: 'NA',
    },
  },
  data() {
    return {
      chartjsData,
      statisticsOrder: {
        // series: this.series,
        chartOptions: {
          // labels: this.labels,
          chart: {
            type: 'bar',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: -20,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 0,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning, $themeColors.danger],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            custom({
              series, seriesIndex, dataPointIndex, w,
            }) {
              let x = series[seriesIndex][dataPointIndex]
              x = parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

              return `${'<div class="custom-tooltip">'
                  + '<span>'} MYR ${x}</span>`
                  + '</div>'
            },
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      // const { labels } = this
      const { series } = this
      return {
        labels: series[0].label,
        datasets: [{
          data: series[0].data, backgroundColor: '#28dac6', borderColor: 'transparent', _meta: {},
        }],
      }
    },
    luxonTodayDate() {
      return DateTime.fromJSDate(new Date())
    },
  },
  methods: {
    cardClicked(param) {
      const todayDate = DateTime.fromJSDate(new Date())

      switch (param) {
        case 'today':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'day', selectedDate: todayDate.toFormat('dd-MM-yyyy') },
          })

          break
        case 'yesterday':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'day', selectedDate: todayDate.plus({ days: -1 }).toFormat('dd-MM-yyyy') },
          })

          break
        case 'month':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'month', selectedDate: todayDate.toFormat('dd-MM-yyyy') },
          })
          break
        case 'lastmonth':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'month', selectedDate: todayDate.plus({ months: -1 }).toFormat('dd-MM-yyyy') },
          })
          break
        case 'year':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'year', selectedDate: todayDate.toFormat('dd-MM-yyyy') },
          })

          break
        case 'lastyear':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'year', selectedDate: todayDate.toFormat('dd-MM-yyyy') },
          })
          break
        case 'lastsevendays':
          this.$router.push({
            name: 'administration-accounts-details-reports-viewer',
            params: { id: this.accountId, type: 'week', selectedDate: todayDate.toFormat('dd-MM-yyyy') },
          })

          break
        // case 'settlement':
        //   this.$router.push({
        //     name: 'administration-accounts-details-settlement',
        //   })
        //
        //   break
        default:
      }
      // alert(todayDate)
    },
  },
}
</script>

<style lang="scss">
.custom-tooltip { padding: 3px 5px; font-size: 0.8rem}
</style>
