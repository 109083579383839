<template>
  <div>
    <div class="d-flex justify-content-end mb-1">

      <b-button
        v-if="IS_ADMIN_PORTAL"
        size="sm"
        :variant="reloadEnable? 'warning' : 'dark'"
        @click="toggleReloader"
      >
        <template v-if="reloadEnable">
          Turn Off Reload
        </template>
        <template v-else>
          Turn On Reload
        </template>
      </b-button>
      <b-button
        class="ml-1"
        size="sm"
        @click="refetchData"
      >

        Reload Cards

      </b-button>
      <b-button
        class="ml-1"
        size="sm"
        @click="refetchAllCards"
      >

        Reload All

      </b-button>

      <b-button
        v-if="IS_ADMIN_PORTAL && !isFavourite"
        class="ml-1"
        size="sm"
        variant="dark"
        @click="addFavourite"
      >

        Add Favourite

      </b-button>
      <b-button
        v-if="IS_ADMIN_PORTAL && isFavourite"
        class="ml-1"
        size="sm"
        variant="warning"
        @click="removeFavourite"
      >

        Remove Favourite

      </b-button>
    </div>

    <b-row>
      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Sale (Today)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="dailyDashboardData.today_sale"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Sale (Yesterday)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="dailyDashboardData.yesterday_sale"
            />
          </h2>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Payout (Today)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.today_payout"
            />
          </h2>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="3"
      >
        <b-card
          body-class="border border-primary pb-50"
        >
          <h6>Total Payout (Yesterday)</h6>

          <h2 class="font-weight-bolder mb-1">
            <vue-numeric
              read-only
              :precision="2"
              :currency="globalCurrency"
              separator=","
              :value="payoutDashboardData.yesterday_payout"
            />
          </h2>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <h6>Pending Bill</h6>

              <h2
                class="font-weight-bolder mb-1"
                :class="crmBillDashboardData.pending_count < 150 ? '' : 'text-danger'"
              >
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.pending_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Success Bill</h6>
                <div style="margin-top: -2px">
                  SR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="billSuccessRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.success_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Failed Bill</h6>
                <div style="margin-top: -2px">
                  FR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="billFailRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.failed_count"
                />
              </h2>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body>
              <div class="d-flex justify-content-between align-items-center px-2 py-1">

                <h5 class="mb-0">
                  Recent Bill
                </h5>

                <b-button
                  size="sm"
                  @click="reloadBillTable"
                >
                  Reload
                </b-button>
              </div>

              <b-table
                show-empty
                responsive
                :fields="tableHeader"
                :items="adminRecentBillsMinuteData"
              >
                <template #empty>
                  <div class="p-2 text-center">
                    No Records
                  </div>
                </template>
                <template #cell(avg_time_to_success_seconds)="row">
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    :value="row.item.avg_time_to_success_seconds || 0"
                  />
                </template>

                <template #cell(combined_count)="row">
                  <span class="text-success">{{ row.item.success_count }}</span> / <span class="text-warning">{{ row.item.pending_count }}</span> / <span class="text-danger">{{ row.item.failed_count }}</span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <h6>Pending Payout</h6>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  :class="crmPayoutDashboardData.pending_count < 150 ? '' : 'text-danger'"
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.pending_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>
                  Success Payout
                </h6>
                <div style="margin-top: -2px">
                  SR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="payoutSuccessRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.success_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Failed Payout</h6>
                <div style="margin-top: -2px">
                  FR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="payoutFailRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.failed_count"
                />
              </h2>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card
              no-body
            >
              <div class="d-flex justify-content-between align-items-center px-2 py-1">

                <h5 class="mb-0">
                  Recent Payout
                </h5>

                <b-button
                  size="sm"
                  @click="reloadPayoutTable"
                >
                  Reload
                </b-button>
              </div>
              <b-table
                responsive
                show-empty
                :fields="tableHeader"
                :items="adminRecentPayoutMinuteData"
              >
                <template #empty>
                  <div class="p-2 text-center">
                    No Records
                  </div>
                </template>
                <template #cell(avg_time_to_success_seconds)="row">
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    :value="row.item.avg_time_to_success_seconds || 0"
                  />
                </template>

                <template #cell(combined_count)="row">
                  <span class="text-success">{{ row.item.success_count }}</span> / <span class="text-warning">{{ row.item.pending_count }}</span> / <span class="text-danger">{{ row.item.failed_count }}</span>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BCard, BIcon, BIconArrowClockwise } from 'bootstrap-vue'
import { DateTime } from 'luxon'

export default {
  components: { BCard, BIcon, BIconArrowClockwise },
  data() {
    return {
      reloadEnable: false,
    }
  },
  computed: {
    isFavourite() {
      const fav = this.$store.getters['accounts/getFavouriteAccount']

      if (Array.isArray(fav)) {
        let found = false
        for (let i = 0; i < fav.length; i++) {
          if (fav[i].id === parseInt(this.$route.params.id, 10)) {
            found = true
            break
          }
        }
        return found
      }
      return false
    },
    tableHeader() {
      return [
        { key: 'minute_slot', label: 'Time' },
        { key: 'txn_per_minute', label: 'TXN/min' },
        { key: 'combined_count', label: 'S/P/F' },
        // { key: 'pending_count', label: 'Pending' },
        // { key: 'failed_count', label: 'Failed' },
        { key: 'avg_time_to_success_seconds', label: 'Success AvgTime(s)' },
        // { key: 'fastest_time_seconds', label: 'Fast(s)' },
        // { key: 'slowest_time_seconds', label: 'Slow(s)' },
      ]
    },
    billFailRate() {
      if (typeof this.crmBillDashboardData.success_count === 'number' && typeof this.crmBillDashboardData.failed_count === 'number') {
        const fr = 100 - ((this.crmBillDashboardData.success_count / (this.crmBillDashboardData.success_count + this.crmBillDashboardData.failed_count)) * 100)
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(fr)) {
          return 0
        }
        return fr
      }
      return 0
    },
    billSuccessRate() {
      if (typeof this.crmBillDashboardData.success_count === 'number' && typeof this.crmBillDashboardData.failed_count === 'number') {
        const sr = (this.crmBillDashboardData.success_count / (this.crmBillDashboardData.success_count + this.crmBillDashboardData.failed_count)) * 100
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(sr)) {
          return 0
        }
        return sr
      }
      return 0
    },
    payoutFailRate() {
      if (typeof this.crmPayoutDashboardData.success_count === 'number' && typeof this.crmPayoutDashboardData.failed_count === 'number') {
        const fr = 100 - ((this.crmPayoutDashboardData.success_count / (this.crmPayoutDashboardData.success_count + this.crmPayoutDashboardData.failed_count)) * 100)
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(fr)) {
          return 0
        }
        return fr
      }
      return 0
    },
    payoutSuccessRate() {
      if (typeof this.crmPayoutDashboardData.success_count === 'number' && typeof this.crmPayoutDashboardData.failed_count === 'number') {
        const sr = (this.crmPayoutDashboardData.success_count / (this.crmPayoutDashboardData.success_count + this.crmPayoutDashboardData.failed_count)) * 100
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(sr)) {
          return 0
        }
        return sr
      }
      return 0
    },
    currentViewAccount() {
      const cva = this.$store.getters['accounts/getCurrentViewAccount']
      return cva
    },
    payoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCustomerDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        monthly_payout: 0, yesterday_payout: 0, last_week_payout: 0, today_payout: 0, last_month_payout: 0, year_payout: 0, last_year_payout: 0, total_payout: 0,
      }
    },
    crmPayoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCrmPayoutDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        pending_count: 0,
        success_count: 0,
        failed_count: 0,
      }
    },

    adminRecentBillsMinuteData() {
      const billsMinute = this.$store.getters['dashboard/adminRecentBillsMinuteData']
      if (billsMinute) {
        return billsMinute.result
      }
      return {

      }
    },
    adminRecentPayoutMinuteData() {
      const payoutMinute = this.$store.getters['dashboard/adminRecentPayoutMinuteData']
      if (payoutMinute) {
        return payoutMinute.result
      }
      return {

      }
    },

    crmBillDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCrmBillDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        pending_count: 0,
        success_count: 0,
        failed_count: 0,
      }
    },
    dailyDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCustomerDailyDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        monthly_sale: 0,
        yesterday_sale: 0,
        last_week_sale: 0,
        today_sale: 0,
        last_month_sale: 0,
        year_sale: 0,
        last_year_sale: 0,
        total_sale: 0,
      }
    },
  },
  async mounted() {
    const payload = {
      id: this.$route.params.id,
      start_date: DateTime.now().toFormat('dd-MM-yyyy'),
      end_date: DateTime.now().toFormat('dd-MM-yyyy'),
    }

    const payloadCrm = {
      account_id: this.$route.params.id,
      start_date: DateTime.now().toFormat('dd-MM-yyyy'),
      end_date: DateTime.now().toFormat('dd-MM-yyyy'),
    }

    await this.$store.dispatch('dashboard/getAdminCustomerDashboard', payload).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getAdminCustomerDailyDashboard', payload).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getCrmReportingPayout', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getCrmReportingBill', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getRecentCustomerBillsMinute', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getRecentCustomerPayoutMinute', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })

    const breadcrumbUpdatePayload = [
      { title: 'Accounts', route: 'administration-accounts' },
      { title: this.currentViewAccount.account_name },
      { title: 'Live Transaction' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },

  beforeDestroy() {
    this.toggleReloader(false)
  },

  methods: {
    reloadBillTable() {
      const payloadCrm = {
        account_id: this.$route.params.id,
        start_date: DateTime.now().toFormat('dd-MM-yyyy'),
        end_date: DateTime.now().toFormat('dd-MM-yyyy'),
      }
      this.$store.dispatch('dashboard/getRecentCustomerBillsMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
    },
    reloadPayoutTable() {
      const payloadCrm = {
        account_id: this.$route.params.id,
        start_date: DateTime.now().toFormat('dd-MM-yyyy'),
        end_date: DateTime.now().toFormat('dd-MM-yyyy'),
      }
      this.$store.dispatch('dashboard/getRecentCustomerPayoutMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
    },
    toggleReloader(flag = true) {
      let timer
      this.reloadEnable = !this.reloadEnable

      if (!flag) {
        clearInterval(timer)
        return
      }

      const _this = this
      if (this.reloadEnable) {
        timer = setInterval(() => {
          if (!this.reloadEnable) {
            clearInterval(timer)
            return
          }
          _this.reloader()
        }, 30000)
      } else {
        clearInterval(timer)
      }
    },

    async refetchData() {
      const payload = {
        id: this.$route.params.id,
      }

      await this.$store.dispatch('dashboard/getAdminCustomerDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getAdminCustomerDailyDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })

      const payloadCrm = {
        account_id: this.$route.params.id,
        start_date: DateTime.now().toFormat('dd-MM-yyyy'),
        end_date: DateTime.now().toFormat('dd-MM-yyyy'),
      }

      await this.$store.dispatch('dashboard/getCrmReportingPayout', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getCrmReportingBill', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })

      await this.$store.dispatch('dashboard/getRecentCustomerBillsMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getRecentCustomerPayoutMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
    },
    async removeFavourite() {
      const fav = await this.$store.getters['accounts/getFavouriteAccount']

      const newFav = [
        ...fav,
      ]

      const favIndex = newFav.findIndex(element => element.id === this.$route.params.id)
      if (favIndex > -1) {
        newFav.splice(favIndex, 1)
      }

      await this.$store.dispatch('accounts/updateFavouriteAccount', newFav)
    },
    async addFavourite() {
      const fav = await this.$store.getters['accounts/getFavouriteAccount']

      const newFav = [
        ...fav,
      ]

      const favIndex = newFav.findIndex(element => element.id === this.$route.params.id)
      if (favIndex) {
        const pushObj = {
          name: this.currentViewAccount.name,
          id: this.currentViewAccount.id,
        }
        newFav.push(pushObj)
      }

      console.log('hi')
      await this.$store.dispatch('accounts/updateFavouriteAccount', newFav)
    },
    async refetchAllCards() {
      const payload = {
        id: this.$route.params.id,
      }

      await this.$store.dispatch('dashboard/getAdminCustomerDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getAdminCustomerDailyDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })

      const payloadCrm = {
        account_id: this.$route.params.id,
        start_date: DateTime.now().toFormat('dd-MM-yyyy'),
        end_date: DateTime.now().toFormat('dd-MM-yyyy'),
      }

      await this.$store.dispatch('dashboard/getCrmReportingPayout', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getCrmReportingBill', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })

      await this.$store.dispatch('dashboard/getRecentCustomerBillsMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getRecentCustomerPayoutMinute', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })

      this.reloadBillTable()
      this.reloadPayoutTable()
    },
    async reloader() {
      const payload = {
        id: this.$route.params.id,
      }

      await this.$store.dispatch('dashboard/getAdminCustomerDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getAdminCustomerDailyDashboard', payload).then(res => {
        console.log('res.data', res.data.data)
      })

      const payloadCrm = {
        account_id: this.$route.params.id,
        start_date: DateTime.now().toFormat('dd-MM-yyyy'),
        end_date: DateTime.now().toFormat('dd-MM-yyyy'),
      }

      await this.$store.dispatch('dashboard/getCrmReportingPayout', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
      await this.$store.dispatch('dashboard/getCrmReportingBill', payloadCrm).then(res => {
        console.log('res.data', res.data.data)
      })
    },
  },
}
</script>

<style>

</style>
