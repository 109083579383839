<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ title }}
        </h4>
        <b-button
          v-if="features.buttons.createButton"
          variant="primary"
          size="sm"
          @click="create"
        >
          Create New
        </b-button>
        <b-button
          v-if="features.buttons.customButton"
          variant="primary"
          size="sm"
          @click="customButtonAction(features.buttons.customButtonActionType)"
        >
          {{ features.buttons.customButtonLabel }}
        </b-button>
      </template>

      <b-row
        v-if="!!tableSettings"
        class="border-top"
      >
        <b-col cols="4">
          <b-form-group
            class="my-2"
            label-size="sm"
          >
            <template v-if="features.buttons.refresh">
              <b-button
                size="sm"
                class="mx-2"
                @click="initDataGrid(dataGridEndpoint)"
              >
                <i class="fa fa-sync" /> Refresh
              </b-button>
            </template>
            <template v-if="features.perPageDropdown">
              <b-form-select
                v-model="selectedPerPage"
                :class="[{'ml-2': !features.buttons.refresh}]"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="initDataGrid(dataGridEndpoint)"
              />
            </template>
          </b-form-group>
        </b-col>

        <b-col cols="8">
          <div class="mt-2 mr-1">

            <template v-if="features.filter">
              <b-col>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="text"
                    placeholder="Type to filter"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </template>
            <template v-if="features.search">
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <template v-if="features.datepicker">
                        <b-col>
                          <b-input-group size="sm">
                            <flat-pickr
                              v-model="rangeDate"
                              placeholder="Select Date"
                              class="form-control form-control-sm"
                              :config="{ mode: 'range',
                                         maxDate: new Date().fp_incr(1),
                                         dateFormat: 'd-m-Y',}"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-secondary"
                                type="button"
                                title="Clear"
                                data-clear

                                @click="clearDateSearch"
                              >
                                Clear
                              </button>

                            </div>
                          </b-input-group>
                        </b-col>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-select
                      v-model="searchField"
                      :options="searchOptionsDropdown"
                      size="sm"
                    />
                  </b-col>
                  <b-col>
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="searchTerm"
                        type="text"
                        placeholder="Type to Search"
                      />

                      <b-input-group-append>
                        <b-button
                          v-if="searchTerm"
                          @click="initDataGrid()"
                        >Clear</b-button>
                        <b-button
                          :disabled="!rangeDate && !searchTerm"
                          @click="searchGrid()"
                        >Search</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>

              </b-col>

            </template>
          </div>
        </b-col>
      </b-row>

      <b-table
        :filter="filter"
        :no-local-sorting="true"
        striped
        bordered
        responsive
        show-empty
        hover
        :items="GridData"
        :busy="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="TableHeader"
        @sort-changed="sortingChanged"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <br>
            <strong>Loading...</strong>
          </div>
        </template>
        <template
          v-for="x in TableHeader"
          v-slot:[getTableKey(x)]="row"
        >
          <leanpay-datatable-field
            :key="x.id"
            :row-data="row"
            :col-name="x.key"
            :col-type="x.type"
          />
        </template>

        <template
          v-if="features.showDTDetails"
          #cell(show_details)="row"
        >
          <template v-if="row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="MinusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
          <template v-if="!row.detailsShowing">
            <b-button
              variant="link"
            >
              <feather-icon
                size="16"
                icon="PlusCircleIcon"
                class="mr-50"
                @click="row.toggleDetails"
              />
            </b-button>
          </template>
        </template>

        <!--Show Details Data-->
        <template #row-details="row">
          <b-card>
            <template
              v-for="x in conf.tableData().tableHeader"
            >
              <b-row
                v-if="x.showInDetails"
                :key="`sss${x.key}`"
                class="mb-1"
              >
                <b-col
                  class="text-right"
                  cols="3"
                >
                  {{ humanise(x.label) }}
                </b-col>
                <b-col>
                  <leanpay-datatable-field
                    :key="x.id"
                    :row-data="row"
                    :col-name="x.key"
                    :col-type="x.type"
                  />
                </b-col>
              </b-row>
            </template>
          </b-card>
        </template>

        <template #cell(adminActions)="row">
          <template v-if="adminActions.length > 0">
            <b-button-group size="sm">
              <template v-for="(btn,index) in adminActions">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="adminActionBtn(row.item, row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>
            </b-button-group>
          </template>
        </template>

        <template #cell(actions)="row">
          <template v-if="TableActions.length > 0">
            <b-button-group size="sm">
              <template v-for="(btn,index) in TableActions">
                <template v-if="btn.action === 'view'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="viewRow(row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'edit'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="editRow(row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>
                <template v-if="btn.action === 'remove'">
                  <b-button
                    :key="`button_actions-${index}`"
                    :variant="btn.variant"
                    @click="deleteRow(row.item[btn.param])"
                  >
                    <feather-icon
                      v-if="btn.btnIcon"
                      :size="btn.iconSize"
                      :icon="btn.btnIcon"
                    />
                    <template v-if="!btn.btnOnly">
                      {{ btn.btnLabel }}
                    </template>
                  </b-button>
                </template>

              </template>
            </b-button-group>
          </template>

        </template>

        <!--SUBSCRIPTION ONLY-->
        <template #cell(subscription_plan_name)="data">
          {{ data.item.Subscription.plan_name }}
        </template>

        <!--REUSABLE SYSTEM WIDE-->
        <template #cell(record_status)="row">
          <span :class="[{'text-success': row.item.record_status === 1}, {'text-warning': row.item.record_status === 2}]">
            {{ displaySystemConstantsLabel('record_status', row.item.record_status) }}</span>
        </template>

        <template #cell(invoice_status)="row">
          <b-badge
            size="sm"
            :class="[{'badge-success': row.item.invoice_status_id === 2},{'badge-danger': row.item.invoice_status_id === 3}, {'badge-warning': row.item.invoice_status_id === 1}]"
          >
            {{ row.item.invoice_status }}
          </b-badge>
        </template>

        <!--COLLECTION URL-->
        <template #cell(url)="row">
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ collectionBaseUrl }}</pre>
          <b-link
            v-if="row.item.collection_method === 1"
            target="_blank"
            :href="`${collectionBaseUrl}${row.item.uuid}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
          <b-link
            v-if="row.item.collection_method === 2"
            target="_blank"
            :href="`${collectionBaseUrl}${row.item.uuid}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
        </template>

        <!--BILL PAYMENT URL-->
        <template #cell(invoiceUrl)="row">
          <pre v-if="debugMode && $store.getters['auth/getDebugToggle']">{{ invoiceBaseUrl }}</pre>
          <b-link
            target="_blank"
            :href="`${invoiceBaseUrl}${row.item.invoice_no}`"
          ><feather-icon
            icon="ExternalLinkIcon"
          /></b-link>
        </template>

        <!--AUDIT URL-->
        <template #cell(auditUrl)="row">
          <code>{{ row.item.url }}</code>
        </template>

      </b-table>

      <LeanpayDatatablePagination
        :grid-settings="GridSettings"
        :selected-per-page="selectedPerPage"
        :current-page="currentPage"
        :search-field="searchField"
        :is-busy="isBusy"
        :table-settings="tableSettings"
        @paginationClicked="pagination($event)"
      />
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import humaniseMixin from '@/common/humanise.mixin'
import LeanpayDatatablePagination from '@/leanpay-components/LeanpayDatatablePagination.vue'
import LeanpayDatatableField from '@/leanpay-components/LeanpayDatatableField.vue'

export default {
  components: { LeanpayDatatableField, LeanpayDatatablePagination, flatPickr },
  mixins: [humaniseMixin],
  props: {
    extraParams: {
      required: true,
    },
    storeModule: {
      required: true,
      type: String,
    },
    storeDataEndpoint: {
      required: true,
      type: String,
    },
    loadSystemConstant: {
      type: Boolean,
      default: false,
    },
    storeGetterEndpoint: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    queryParam: {
      required: false,
      type: String,
    },
    rawDataKey: {
      required: false,
      type: String,
      default: 'getRawData',
    },
    defaultSortField: {
      required: false,
      type: String,
      default: 'created_at',
    },
    defaultSearchField: {
      required: true,
      type: String,
    },
    rawData: {
      required: false,
      type: Object,
    },
    paginationStateKey: {
      required: false,
      type: String,
    },
    searchOptionsDropdown: {
      required: false,
      type: Array,
    },
    conf: {
      required: false,
      type: Object,
    },
    pageConf: {
      required: false,
      type: Object,
    },
    features: {
      required: false,
      type: Object,
      default() {
        return {
          datatableFeatures: {
            buttons: {
              createButton: false,
              refresh: false,
            },
            perPageDropdown: true,
            filter: true,
            pagination: true,
            search: false,
          },
        }
      },
    },
  },
  data() {
    return {
      rangeDate: null,
      cardTitle: 'title',
      dataGridEndpoint: [],
      module: 'development',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortDesc: true,

      record_status: 1, // active only
      searchField: '',
      sortBy: 'desc',
      sort: '',
      isTableLoading: false,
    }
  },
  computed: {
    processedDateRange() {
      let dateRange = this.rangeDate
      if (dateRange) {
        dateRange = dateRange.split(' to ')
        if (dateRange.length > 1) {
          return {
            startDate: dateRange[0],
            endDate: dateRange[1],
          }
        }
        return {
          startDate: dateRange[0],
          endDate: dateRange[0],
        }
      }
      return this.defaultDateRange
    },
    formSchema() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      // return Object.getOwnPropertyNames(list[0])
      const x = []
      for (const [key, value] of Object.entries(list[0])) {
        x.push({ name: key, type: typeof value })
      }
      return x
    },
    tableConfig() {
      if (this.conf) {
        return this.conf.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridSettings.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    adminActions() {
      const x = this.tableConfig.adminActions
      return x
    },
    sortObj() {
      const obj = {
        parameter_name: this.sort,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.storeModule}/${this.storeGetterEndpoint}`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.storeModule}/${this.rawDataKey}`](this.paginationStateKey)

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    this.isBusy = false

    this.searchField = this.defaultSearchField
    this.sort = this.defaultSortField

    if (this.loadSystemConstant) {
      await this.$store.dispatch('systemConstants/getSystemConstants')
    }

    this.isBusy = true

    let payload = {
      start_date: this.processedDateRange.startDate,
      end_date: this.processedDateRange.endDate,
      limit: this.tableSettings.perPage,
      skip: 0,
      record_status: 1,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: this.sortObj,
      ...this.extraParams,
    }

    if (this.queryParam && typeof this.queryParam === 'string') {
      const y = this.queryParam
      const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

      payload = { ...obj, ...payload }
    }

    await this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
      this.isBusy = false
    })
  },
  methods: {
    getTableKey(x) {
      return `cell(${x.key})`
    },
    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)
      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === parseInt(id, 10))
        // return x
        // return this.humanise(
        //   x[0].name,
        // )
      }
      return 'NA'
    },
    editRow(itemId) {
      console.log('edit', itemId)
      this.$emit('actionBtn-clicked', { location: 'edit', value: itemId })
      const x = this.TableActions.filter(el => el.action === 'edit')
      this.$router.push({
        name: x[0].pageRoute,
        params: { id: itemId, mode: 'edit' },
      })
    },
    deleteRow(itemId) {
      this.$emit('actionBtn-clicked', { location: 'delete', value: itemId })
      console.log('delete', itemId)
    },
    clearDateSearch() {
      this.rangeDate = null
      this.initDataGrid()
    },
    adminActionBtn(row, itemId) {
      this.$emit('adminBtn-clicked', { rowData: row, location: 'custom', value: itemId })
      console.log('custom', itemId)
    },
    viewRow(itemId) {
      console.log('view', itemId)
      this.$emit('actionBtn-clicked', { location: 'view', value: itemId })
      const x = this.TableActions.filter(el => el.action === 'view')
      console.log('x', x)
      this.$router.push({
        name: x[0].pageRoute,
        params: { id: itemId, mode: 'view' },
      })
    },
    create() {
      console.log('create')
      this.$router.push({
        name: this.features.buttons.createButtonRoute,
      })
    },
    customButtonAction(e) {
      console.log('customButtonAction')
      this.$emit('custom-button-action', e)
    },
    pagination(e) {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: (e - 1) * this.tableSettings.perPage,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParams,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.isBusy = false
        this.currentPage = e
      })
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      // alert(ctx.sortBy)

      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: ctx.sortBy,
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
        ...this.extraParams,
      }

      if (this.searchField !== '') {
        payload = {
          start_date: this.processedDateRange.startDate,
          end_date: this.processedDateRange.endDate,
          limit: this.tableSettings.perPage,
          skip: 0,
          record_status: 1,
          search: {
            search_enable: false,
            search_key: '',
            search_column: '',
            search_replace_word_enable: true,
            search_word_replace: ' ',
            search_word_replace_to: '_',
          },
          sort: {
            parameter_name: ctx.sortBy,
            sort_type: this.sortDesc ? 'desc' : 'asc',
          },
          ...this.extraParams,
        }
      }

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid() {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParams,
      }
      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }
      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid() {
      let payload = {
        start_date: this.processedDateRange.startDate,
        end_date: this.processedDateRange.endDate,
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
        ...this.extraParams,
      }

      if (this.queryParam && typeof this.queryParam === 'string') {
        const y = this.queryParam
        const obj = { [y]: this.$route.params[this.toCamelCase(this.queryParam)] }

        payload = { ...obj, ...payload }
      }

      this.isBusy = true
      this.$store.dispatch(`${this.storeModule}/${this.storeDataEndpoint}`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>

<style>
.show-details.col-width { width: 20px; padding: 0}
</style>
